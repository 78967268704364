@import "styles/variables";

.clues {
  display: grid;
  // justify-content: center;
  grid-template-areas: "across divider down";

  grid-gap: $d-lg;

  h3 {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  &__divider {
    background: $divider-color;
    justify-self: center;
    width: 1px;
    grid-area: divider;
  }

  &__across, &__down {
    width: 300px;
    // padding: $d-lg;

  }

  &__across {
    grid-area: across;
    justify-self: end;
  }

  &__down {
    grid-area: down;
  }

  &__list {
    text-align: left;
    font-size: 1rem;

    & > li {
      margin-bottom: $d-lg;
      outline: none;
    }
  }

}

@media (max-width: $mobile-break) {
  .clues {
    grid-template-areas: "across" "divider" "down";

    &__across, &__down {
      width: initial;
      justify-self: stretch;
    }

    &__divider {
      height: 1px;
      width: 100%;
    }
  }
}
