@import "styles/variables";

.main {
  padding: $d-lg;
}

@media (max-width: $mobile-break) {
  .main {
    padding: $d-md;
  }
}
