@import "styles/variables";

.app {
  max-width: $content-width;

  margin-left: auto;
  margin-right: auto;

  position: relative;

  &__content {
    padding: $d-lg;
  }
}

@media (max-width: $mobile-break) {
  .app__content {
    padding: $d-md;
  }
}
