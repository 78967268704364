@import "styles/variables";

.status {
  color: $text-secondary;
  text-align: center;
  font-size: $sm;
  line-height: 1rem;
  height: 1rem;

  &--warning {
    color: $danger;
  }
}
