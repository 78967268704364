@import "styles/variables";

.header {
  padding: $d-md 0;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;

  background: white;
  border-bottom: 1px solid $divider-light-color;
}
