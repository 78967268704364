@import "styles/variables";

.grid-container {
  display: flex;
  justify-content: center;
  margin: $d-xl 0;
  position: relative;
}

.grid__input {
  opacity: 0;
  height: 0;
  width: 0;
}

.grid {
  display: grid;

  border-left: 1px solid $border-color;
  border-top: 1px solid $border-color;

  outline: none;

  & > * {
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }
}
