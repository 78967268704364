@import "styles/variables";

.logo {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;

  span:first-child {
    color: $primary;
  }

  span:last-child:not(:first-child) {
    color: $text-primary;
  }
}
