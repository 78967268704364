@import "styles/variables";

.viewer-messages {
  text-align: center;
  padding: $d-xl;

  font-size: $sm;

  &__error {
    color: $danger;
  }
}
