@import "styles/variables";

.viewer-complete-dialog {
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 600;

    color: $gold;

    svg {
      margin-right: $d-md;
    }
  }

  &__text {
    font-weight: 600;
  }

  .MuiPaper-root {
    background: scale-color($gold, $lightness: 92%);
    border: 5px solid $gold;
  }
}
