@import "styles/variables";

h1.title {
  text-align: center;
  margin-top: $d-lg;
  margin-bottom: $d-xl + $d-md;
  font-size: 2rem;

  outline: none;
}
