$primary: #25ccb8;
$primary-light: scale-color($primary, $lightness: -20%);
$primary-highlight: #c1f5ee;
$border-color: #999;
$divider-color: #ccc;
$divider-light-color: #eee;
$yellow: #ffda00;
$gold:#f0ca00;
$secondary: orange;
$danger: #fc2125;
$highlight-blue: lightblue;

$text-primary: rgba(0, 0, 0, 0.9);
$text-secondary: rgba(0, 0, 0, 0.54);

$content-width: 768px;
$mobile-break: 680px;

$grid-size: 3rem;

$d-sm: 0.25rem;
$d-md: 0.5rem;
$d-lg: 1.00rem;
$d-xl: 2.00rem;

$sm: 0.85rem;
$md: 1rem;
$lg: 1.5rem;
$xl: 2rem;
