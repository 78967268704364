@import "styles/variables";

.new-puzzle-dialog {
  &__control {
    width: 280px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: $d-lg;
    padding-bottom: 0;
  }

  &__indicator {
    display: flex;
    align-items: center;
  }

  &__size {
    font-size: 3rem;
  }

  &__times {
    font-size: 2rem;
    margin: 0 $d-md;
  }

  &__label {
    font-size: 0.75rem;
    text-align: center;
  }
}


@media (max-width: $mobile-break) {
  .new-puzzle-dialog {
    &__control {
      width: 100%;
    }

    &__size {
      font-size: 2rem;
    }
  }
}
