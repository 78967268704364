@import "styles/variables";

.grid-cell {
  background-color: white;

  &--block {
    background-color: black;
  }

  &--selected {
    background-color: $yellow;

    @media print {
      background-color: white;
    }
  }

  &--highlighted {
    background-color: $primary-highlight;

    @media print {
      background-color: white;
    }
  }

  &__clue {
    font-size: 12px;
  }

  &__content {
    font-size: 32px;
    pointer-events: none;
  }
}
