@import "styles/variables";

.toolbar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: $text-secondary;

  &__button-group--left, &__button-group--right, &__button-group--center {
    white-space: nowrap;
  }

  &__button-group--left {
    justify-self: start;
  }

  &__button-group--center {
    border-radius: 8px;
    justify-self: center;

    button:not(:last-child) {
      border-right: 1px solid $divider-light-color;
    }
  }

  &__button-group--right {
    justify-self: end;
  }
}

@media (max-width: $mobile-break) {
  .toolbar__button {
    svg {
      font-size: 1.25rem;
    }

    padding: 6px !important;
  }
}
